export default defineI18nConfig(() => ({
  legacy: false,
  locale: "hu",
  fallbackLocale: "hu",
  messages: {
    hu: {
      menu: {
        home: "Főoldal",
        features: "Jellemzők",
        templates: "Oldalsablonok",
        pricing: "Árak",
        about: "Rólunk",
        register: "Regisztráció",
        login: "Belépés",
        register_login: "Start",
        logout: "Kijelentkezés",
        contact: "Kapcsolat",
      },
      seoTitle: "Menedzselt weboldalak saját adminnal",
      seoDesc:
        "Fedezze fel a menedzselt weboldalak előnyeit saját adminisztrációs felülettel. Professzionális weboldalkezelés, egyszerű tartalomfrissítés és teljes irányítás a webes jelenlét felett.",

      /*hero section*/
      heroTitle: "Gyors landing oldal saját adminnal",
      heroDesc:
        "A tartalmaid külön kezelheted a design-tól, sőt magát a dizájn elemeket is könnyedén cserélgetheted. ",
      heroCTAMore: "Tovább",
      heroCTAContact: "Kapcsolat",

      /*features section*/
      featureTitle: "Minden  adott, ami egy saját weboldalhoz kell",
      featureDesc:
        "Az általunk létrehozott weboldal nem csupán megkönnyíti a hatékony online jelenlét kialakítását, hanem hosszú távon is kifizetődő befektetés. Új weboldala  villámgyorsan fog betöltődni, és biztonságosabb is lesz, mint a monolit, (pl WordPress) megoldások. Íme hat indok, amiért érdemes szakértő szolgáltatásunkat választani landing oldalak fejlesztéséhez:",

      feature1Title: "Gyorsaság és Teljesítmény",
      feature1Desc:
        "A headless architektúra lehetővé teszi az oldalak gyorsabb betöltését, és a jobb teljesítményt.",

      feature2Title: "Személyre Szabott Megjelenés",
      feature2Desc:
        "Teljesen egyedi tervezés, amely bemutatja cége identitását és kommunikációs céljait.",

      feature3Title: "Optimalizált Felhasználói Élmény",
      feature3Desc:
        "Kitűnő felhasználói élmény minden eszközön, ami növeli az oldalak konverziós rátáit.",

      feature4Title: "SEO Előnyök",
      feature4Desc:
        "A keresőoptimalizált tartalom és struktúra segít javítani az oldalak keresőmotorokban való láthatóságát.",

      feature5Title: "Biztonság és Megbízhatóság",
      feature5Desc:
        "A wordpresszel ellentétben nem fogják az új oldalt megtámadni, mert nincs rajta semmilyen sebezhetőség.",

      feature6Title: "Folyamatos Támogatás és Karbantartás",
      feature6Desc:
        "Szakértői támogatás az oldal indítása után is, biztosítva az oldal folyamatos frissülését és zökkenőmentes működését.",

      /*templates*/
      templateTitle: "Sablonok azonnali használatra",
      templateDesc:
        "Előre készítettünk sablonokat, amelyek azonnal használhatók. Mindegyiket testreszabott admin felülettel kínáljuk. A sablonok kiválóan alkalmasak különböző iparágak számára, beleértve a kreatív szakembereket, építőipari válalkozásokat bagy éttermeket, stb...",
      atomTemplateTitle: "Sablon fényképészeknek, grafikusoknak",
      atomTemplateDesc:
        "A headless architektúra lehetővé teszi az oldalak gyorsabb betöltését,és a jobb teljesítményt. Admin felület blog funkcióval és referenciák feltöltésével",
      homeMasterTemplateTitle: "Sablon építőipari szakembereknek",
      homeMasterTemplateDesc:
        "A headless architektúra lehetővé teszi az oldalak gyorsabb betöltését,és a jobb teljesítményt. Admin felület a szolgáltatások, és a referenciák feltöltésére",

      /*tecnologies*/
      techTitle: "Technológiák, amiket használunk:",
      nuxtDesc: "headless javascript keretrendszer, amelyben dolgozunk.",
      vueDesc: "A nuxt3 alapjául szolgáló javascript keretrendszer.",
      tailwindDesc: "Oldalaink stílusozásához használt css keretrendszer.",
      htmlDesc: "Html5 szabályos használata.",
      directusDesc: "Biztonságos és személyre szabható adminfelület.",
      nuxtUiDesc:
        "Átfogó gyűjteménye teljesen stílusos és testreszabható UI komponenseknek, amelyek a Nuxt számára lettek tervezve.",

      /*pricing package1*/
      pricingTitle: "Milyen weboldalt szeretnék?",
      pricingSubTitle: "Egyszerű és átlátható árak, meglepetések nélkül.",
      pricingOrderProcessTitle: "Megrendelés menete",
      pricingOrderProcessSubTitle:
        "Görgessen lejjebb, és keressen meg minket. Hamarosan személyesen keressük, és megbeszéljük a teendőket. Kapcsolatfelvétel után részletes ütemezést készítünk, amelyet egyedi igényei szerint módosíthat. ",

      /*landingOnly*/
      landingOnly: "Egyedi weboldal",
      landingOnlyPrice: "200e Ft-tól ",
      landingOnlyTemplates: "Egyéni kérés alapján készül el",
      landingOnlyPages: "max 3 oldal",
      landingOnlySMIntegration:
        "Alap közösségi média integráció (Google, Facebook)",
      landingOnlyCTA: "Kapcsolat",
      // landingOnlySupport: 'támogatás chat-en',
      // landingOnlyDomain: 'ingyenes domén integráció',
      // landingOnlyServer: '1 év ingyenes tárhely',

      /*landingOnly w/ admin*/
      landingAdmin: "Egyedi weboldal saját adminfelülettel",
      landingAdminPrice: "400e Ft-tól ",
      LandingAdminTemplates: "kész oldalsablonból választható",
      landingAdminPages: "korlátlan, saját adminnal",
      landingAdminSMIntegration: "közösségi média integráció",
      landingAdminSupport: "támogatás chat-en",
      landingAdminDomain: "ingyenes domén regisztráció és integráció",
      landingAdminServer: "1 év ingyenes tárhely",
      landingAdminCTA: "Kapcsolat",

      /*landingOnly w/ admin unique*/
      landingAdminUnique: "Egyedi weboldal saját adminfelülettel + SEO",
      landingAdminUniquePrice: "600e Ft-tól",
      LandingAdminUniqueTemplates:
        "kész oldalsablonokból választható vagy egyéni kérés alapján készül el",
      landingAdminUniquePages: "korlátlan, saját adminnal",
      landingAdminUniqueSMIntegration: "közösségi média integráció",
      landingAdminUniqueSupport: "Támogatás chat-en e-mailen vagy telefonon",
      landingAdminUniqueDomain: "ingyenes domén integráció",
      landingAdminUniqueServer: "1 év ingyenes tárhely",
      landingAdminUniqueSEO: "1 év ingyenes keresőoptimalizálás (érdeklődjön)",
      landingAdminUniqueCTA: "Kapcsolat",

      /*about*/
      aboutTitle: "Rólunk",
      aboutDesc: "A webfejlesztés az életünk. Velünk Te is sikeres leszel!",
      aboutTitle2: " Mutassa be weboldalát sikeresen",
      aboutDesc2:
        "Különböző háttérrel rendelkezünk, különböző személyiségeket, tapasztalatokat és készségeket hozunk a munkához. Ez teszi a csapatunkat olyan különlegessé.",
      teamTitle1: "fejlesztés",
      teamTitle2: "marketing",
      teamTitle3: "szerverüzemeltetés",

      /*contact*/
      contactTitle: "Kapcsolat",
      contactSubTitle: "Lépjen kapcsolatba velünk!",
      messageBlockTitle: "Keressen minket:",
      messageBlockSubTitle:
        "Írjon nekünk az űrlapon, a jobb alsó sarokban lévő chat gombra kattintva írjon üzenetet vagy küldjön e-mailt.",
      messageBlockAddress: "2030-Érd, Magyarország.",
      messageBlockEmail: "info@flexi-solutions.net",
      messageError: "Valami nem stimmel",
      messageInputName: "Teljes név",
      messageInputAddress: "E mail cím",
      messageInputMessage: "Üzenet",
      messageInputNameError: "Kérem adja meg a teljes nevét. ",
      messageInputAddressError:
        "Kérem, hogy adjon meg egy valós e-mail címet! ",
      messageInputMessageError: "Kérem, írja meg az üzenetét!",
      messageFormSubmit: "Kérem, adja meg az e-mail címét! ",
      messageSending: "Küldés ...",

      // footerCTA
      footerCTATitle: "Legyen hatékony weboldala",
      footerCTASubTitle:
        "Válasszon minket és mi segítünk a weboldalának hatékonyságában!",
      footerCTAButton: "Kapcsolat",

      //privacy policy
      ppTitle: "Adatvédelmi nyilatkozat",
      ppSubtitle: "Tekintse meg az adatvédelmi irányelveket",
    },

    en: {
      menu: {
        home: "Home",
        features: "Features",
        templates: "Templates",
        pricing: "Pricing",
        about: "About",
        login: "Login",
        register: "Register",
        register_login: "Start",
        logout: "Logout",
        contact: "Contact",
      },
      seoTitle: "Managed websites with custom admin",
      seoDesc:
        "Discover the benefits of managed websites with a custom admin interface. Professional website management, easy content updates, and complete control over your web presence.",

      /*hero*/
      heroTitle: "Fast landing page with its own admin",
      heroDesc:
        "You can manage your content separately from the design, and even easily swap out design elements.",
      heroCTAMore: "Learn More",
      heroCTAContact: "Contact",

      /*features section*/
      featureTitle: "Everything that you need for a website",
      featureDesc:
        "The website we create not only facilitates the establishment of an efficient online presence but is also a long-term profitable investment. Your new website will load lightning-fast and be more secure than monolithic solutions (e.g., WordPress). Here are six reasons why you should choose our expert service for landing page development:",

      feature1Title: "Speed and Performance",
      feature1Desc:
        "The headless architecture allows for faster page loads and better performance.",

      feature2Title: "Customized Appearance",
      feature2Desc:
        "A unique design that showcases your company identity and communication goals.",

      feature3Title: "Optimized User Experience",
      feature3Desc:
        "Excellent user experience on all devices, increasing the conversion rates of the pages.",

      feature4Title: "SEO Benefits",
      feature4Desc:
        "SEO-optimized content and structure help improve the visibility of pages in search engines.",

      feature5Title: "Security and Reliability",
      feature5Desc:
        "Unlike WordPress, your new site will not be vulnerable to attacks as it has no vulnerabilities.",

      feature6Title: "Continuous Support and Maintenance",
      feature6Desc:
        "Expert support even after the launch, ensuring the site is continuously updated and runs smoothly.",

      /*templates*/
      templateTitle: "Templates Ready for Instant Use",
      templateDesc:
        "Our website not only facilitates the creation of an effective online presence but also proves to be a long-term profitable investment. Your new website will load lightning-fast and be more secure than monolithic solutions (e.g., WordPress). Here are six reasons why you should choose our expert service for landing page development:",
      atomTemplateTitle: "Template for Photographers and Graphic Designers",
      atomTemplateDesc:
        "The headless architecture allows for faster page loading and better performance. Admin interface with blog functionality and reference uploads",
      homeMasterTemplateTitle: "Template for Construction Professionals",
      homeMasterTemplateDesc:
        "The headless architecture allows for faster page loading and better performance. Admin interface for uploading services and references",

      /*technologies*/
      techTitle: "Technologies we use:",
      nuxtDesc: "Headless JavaScript framework we work with.",
      vueDesc: "JavaScript framework on which Nuxt3 is based.",
      tailwindDesc: "CSS framework used for styling our pages.",
      htmlDesc: "Proper use of HTML5.",
      directusDesc: "Secure and customizable admin interface.",
      nuxtUiDesc:
        "Comprehensive collection of fully styled and customizable UI components designed for Nuxt.",

      /*pricing package1*/
      pricingTitle: "What website would I prefer?",
      pricingSubTitle: "Simple pricing without surprises.",
      pricingOrderProcessTitle: "Order process",
      pricingOrderProcessSubTitle: "How to order a website",

      /*landingOnly*/
      landingOnly: "Unique website",
      landingOnlyPrice: "From\u2028EUR 500 ",
      landingOnlyTemplates: "Created by custom request",
      landingOnlyPages: "up to 3 pages",
      landingOnlySMIntegration:
        "Basic social media integration (Google, Facebook)",
      landingOnlyCTA: "Contact us",
      // landingOnlySupport: 'support via chat',
      // landingOnlyDomain: 'free domain integration',
      // landingOnlyServer: '1 year free hosting',

      /*landingOnly w/ admin*/
      landingAdmin: "Unique website with its own admin interface",
      landingAdminPrice: `From
EUR 1000`,
      LandingAdminTemplates:
        "choose from pre-made templates or create a custom one",
      landingAdminPages: "Limited with own admin",
      landingAdminSMIntegration: "Advanced social media integration",
      landingAdminSupport: "support via chat",
      landingAdminDomain: "free domain registration and integration",
      landingAdminServer: "1 year free hosting",
      landingAdminCTA: "Contact us",

      /*landingOnly w/ admin unique*/
      landingAdminUnique: "Unique website with its own admin interface + SEO",
      landingAdminUniquePrice: "From\u2028EUR 2 000",
      LandingAdminUniqueTemplates:
        "choose from pre-made templates or create a custom one",
      landingAdminUniquePages: "unlimited, with own admin",
      landingAdminUniqueSMIntegration: "Social media integration",
      landingAdminUniqueSupport: "Support via chat, email, or phone",
      landingAdminUniqueDomain: "free domain registration and integration",
      landingAdminUniqueServer: "1-year free hosting",
      landingAdminUniqueSEO: "1 year free SEO  (Please contact us)",
      landingAdminUniqueCTA: "Contact us",

      /*about*/
      aboutTitle: "About Us",
      aboutDesc:
        "Web development is our life. With us, you will be successful too!",
      aboutTitle2: "Showcase Your Website Successfully",
      aboutDesc2:
        "We come from diverse backgrounds, bringing different personalities, experiences, and skills to the work. This is what makes our team so special.",
      teamTitle1: "Programming",
      teamTitle2: "Marketing",
      teamTitle3: "Hosting",

      /*contact*/
      contactTitle: "Contact",
      contactSubTitle: "Get in touch with us!",
      messageBlockTitle: "Reach us at:",
      messageBlockSubTitle:
        "Write to us using the form, chat to us or send an email.",
      messageBlockAddress: "2030-Érd, Hungary.",
      messageBlockEmail: "info@flexi-solutions.net",
      messageError: "Something went wrong",
      messageInputName: "Full Name",
      messageInputAddress: "Email Address",
      messageInputMessage: "Message",
      messageInputNameError: "Please provide your full name.",
      messageInputAddressError: "Please provide a valid email address!",
      messageInputMessageError: "Please write your message!",
      messageFormSubmit: "Please provide your email address!",
      messageSending: "Sending...",

      // footerCTA
      footerCTATitle: "Make Your Website Efficient",
      footerCTASubTitle:
        "Choose us and we will help improve your website’s efficiency!",
      footerCTAButton: "Contact",

      //privacy policy
      ppTitle: "Privacy policy",
      ppSubtitle: "",
    },
  },
}));
